import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`És un fong paràsit del ginebró que desenvolupa unes prolongacions que penetren dins les cèl·lules de la planta. Les branques del ginebró s’inflen, amb formes tumorals, sobre les quals apareixen les fructificacions gelatinoses, de color groc taronja, de 10-20 x 2-3 mm. Les espores són grans, pedicelades, bicel·lulars, de 75-85 x 12-15 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      